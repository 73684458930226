/**
 * To get the aggregation features of the group row the `groupDisplayType`
 * needed to be changed to `singleColumn` from `groupRows` as `groupRows`
 * does not support the aggregation features.
 *
 * Hack 1
 *
 * in autoGroupColumnDef.cellRendererSelector which blocks the default renderer
 * for the second row.
 *
 * Hack 2
 *
 *
 *  *
 * Without this there the second row would be able to be expanded.
 */

import React, {
  useMemo,
  useRef,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { ICellRendererParams } from 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { Button, EllipsisText, Flex } from 'components/ui';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { globalTheme } from 'styles/global-theme';
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { formatAUD } from 'lib/helpers';

interface StockBookRequiredMobileProps {
  config: any;
  filters?: any;
  stockData: any;
  dataLoading: any;
  onRefReady?: any;
  allCategories?: any;
  productType?: string;
  categories?: {
    mainCategory?: string;
    subCategory?: string | string[];
  };
  hideMainCat?: any;
}

interface StockGroupRow {
  group: string;
  name: string;
  kill: number;
  required: number;
  incoming: number;
  [id: string]: any;
}

const ColorButton = styled(Button)(() => ({
  color: 'white',
  backgroundColor: '#C934E1',
}));

const ValueWrapper = styled(Flex)`
  padding: 4px 0;
`;

export const StockBookRequiredMobile = ({
  stockData,
  onRefReady,
  allCategories,
  productType,
  config,
  categories,
  hideMainCat,
}: StockBookRequiredMobileProps) => {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [gridHeight, setGridHeight] = useState<number>(0);
  const gridRef = useRef<any>();
  const router = useRouter();
  const datesRef = useRef<Record<string, Record<string, string>>>({});

  const numberFormatter = useCallback((params: any) => {
    return params.value?.toLocaleString();
  }, []);

  const calculateGridHeight = useCallback(() => {
    const windowHeight = window.innerHeight;
    const navbarHeight = 190; // Example height of a navbar
    const buttonHeight = 64; // Example height of a button
    const calculatedHeight = windowHeight - navbarHeight - buttonHeight;
    setGridHeight(calculatedHeight);
  }, [window.innerHeight]);

  useEffect(() => {
    calculateGridHeight();
    window.addEventListener('resize', calculateGridHeight);
    return () => {
      window.removeEventListener('resize', calculateGridHeight);
    };
  }, [calculateGridHeight]);

  const getFilteredCategories = useCallback(() => {
    const subCats: { [id: string]: string } = {};
    const groupRows: { [id: string]: string } = {};

    // Build subCategories lookup
    allCategories?.SUB?.values.forEach((subData: any) => {
      subCats[subData.key] = subData.name;
    });

    // Get all valid main categories based on filter
    const validMainCategories = allCategories?.MAIN?.values.filter(
      (mainData: any) =>
        !categories?.mainCategory || mainData.key === categories.mainCategory
    );

    // For each valid main category, get valid subcategories
    validMainCategories.forEach((mainData: any) => {
      const validSubCategories = mainData.sub_categories.filter(
        (subCatKey: string) =>
          !categories?.subCategory?.length ||
          categories.subCategory.includes(subCatKey)
      );

      // Build group rows only for valid combinations
      validSubCategories.forEach((subCatKey: string) => {
        const mainName = !hideMainCat ? mainData.name : '';
        const subCatName = subCats[subCatKey];
        groupRows[`m_${mainData.key}_s_${subCatKey}`] =
          `${mainName} ${subCatName}`.trim();
      });
    });

    return { groupRows, subCats };
  }, [allCategories, categories]);

  const processData = useCallback(
    (incomingData: any) => {
      if (!stockData) return [];

      const emptyData: { [id: string]: StockGroupRow } = {};
      const groups = new Set<string>();
      const { groupRows } = getFilteredCategories();

      datesRef.current = {};

      // Generate groups and dates
      incomingData.forEach((obj: any) => {
        groups.add(obj.group);
        datesRef.current[obj.group] = {
          startDate: config?.settings?.monthly
            ? format(startOfMonth(new Date(obj.date)), 'yyyy-MM-dd')
            : format(startOfWeek(new Date(obj.date)), 'yyyy-MM-dd'),
          endDate: config?.settings?.monthly
            ? format(endOfMonth(new Date(obj.date)), 'yyyy-MM-dd')
            : format(endOfWeek(new Date(obj.date)), 'yyyy-MM-dd'),
        };
      });

      // Initialize empty data structure
      groups.forEach((groupName) => {
        Object.entries(groupRows).forEach(([rowId, rowName]) => {
          emptyData[`${groupName}_${rowId}`] = {
            group: groupName,
            name: rowName,
            incoming: 0,
            required: 0,
            kill: 0,
            dates: datesRef.current[groupName],
          };
        });
      });

      // Populate data
      stockData.forEach((groupData: any) => {
        Object.entries(groupData).forEach(([key, value]) => {
          const match = key.match(/^(incoming|kill)_(m_.*_s_.*)$/);
          if (match && emptyData[`${groupData.group}_${match[2]}`]) {
            emptyData[`${groupData.group}_${match[2]}`][match[1]] +=
              value as number;
          }
        });
      });

      // Convert to array and calculate required values
      return Object.values(emptyData).map((row) => ({
        ...row,
        required: row.kill - row.incoming,
      }));
    },
    [stockData, config?.settings?.monthly, getFilteredCategories]
  );

  const handleClickSummary = (
    startDate: string,
    endDate: string,
    type: string
  ) => {
    router.push(`/stockbook?type=${type}&date=${startDate}&date_to=${endDate}`);
  };

  const purchasedCellRenderer = (props: any) => {
    // Outputs the normal value except for the grouped header row, instead we
    // links to the relevant Stockbook data
    if (props.node.parent.id === 'ROOT_NODE_ID') {
      return (
        <ColorButton
          size='xSmall'
          color='pink'
          onClick={() => {
            handleClickSummary?.(
              datesRef.current[props.node.key]?.startDate,
              datesRef.current[props.node.key]?.endDate,
              productType as string
            );
          }}
          fontSize={4}
        >
          View
        </ColorButton>
      );
    }
    return valueRenderer(props);
  };

  const valueRenderer = (params: any) => {
    return (
      <ValueWrapper padding={0}>
        <EllipsisText
          fontSize={4}
          color={typeof params.value && params.value < 0 ? 'red' : 'black'}
        >
          {typeof params.value === 'number'
            ? formatAUD(params.value, false, false)
            : params.value}
        </EllipsisText>
      </ValueWrapper>
    );
  };

  const columnDefs: any = useMemo(() => {
    return [
      {
        field: 'group',
        rowGroup: true,
        hide: true,
        suppressMenu: true,
        suppressSorting: true,
      },
      {
        field: 'name',
        rowGroup: true,
        hide: true,
        headerClass: 'stockbook-required-header',
        cellRenderer: valueRenderer,
        suppressMenu: true,
      },
      {
        headerName: 'Req',
        headerClass: 'stockbook-required-header',
        field: 'required',
        valueFormatter: numberFormatter,
        cellRenderer: valueRenderer,
        aggFunc: 'sum',
        suppressMenu: true,
        suppressSorting: true,
        minWidth: 80,
        width: 100,
        maxWidth: 250,
      },
      {
        headerName: 'Sch',
        headerClass: 'stockbook-required-header',
        field: 'kill',
        valueFormatter: numberFormatter,
        cellRenderer: valueRenderer,
        aggFunc: 'sum',
        suppressMenu: true,
        suppressSorting: true,
        minWidth: 80,
        width: 100,
        maxWidth: 250,
      },
      {
        headerName: 'Pur',
        headerClass: 'stockbook-required-header',
        field: 'incoming',
        valueFormatter: numberFormatter,
        aggFunc: 'sum',
        cellRenderer: purchasedCellRenderer,
        suppressMenu: true,
        suppressSorting: true,
        minWidth: 100,
        width: 100,
        maxWidth: 250,
      },
    ];
  }, [numberFormatter]);

  const onGridReady = (params: { api: any }) => {
    if (onRefReady && typeof onRefReady === 'function' && gridRef.current) {
      onRefReady(gridRef.current);
    }
  };

  return (
    <>
      <div style={{ height: gridHeight }}>
        <div style={gridStyle} className={'ag-theme-quartz'}>
          <AgGridReact
            ref={gridRef}
            rowData={processData(stockData)}
            columnDefs={columnDefs}
            defaultColDef={{
              flex: 1,
              minWidth: 80,
              cellRenderer: valueRenderer,
            }}
            groupDisplayType={'singleColumn'}
            autoGroupColumnDef={{
              headerName: config?.settings?.monthly ? 'Month' : 'Weekly',
              flex: 1,
              minWidth: 120,
              maxWidth: 250,
              headerClass: 'stockbook-required-header',
              cellRenderer: valueRenderer,
              cellRendererParams: {
                suppressCount: true,
              },
              cellRendererSelector: (params: ICellRendererParams) => {
                if (params.node.parent?.id !== 'ROOT_NODE_ID') {
                  // Blocks the expanding feature on the second row
                  return undefined;
                }

                return {
                  component: 'agGroupCellRenderer',
                };
              },
            }}
            groupRowRendererParams={{
              suppressCount: true,
              suppressPadding: true,
            }}
            getRowStyle={(params) => {
              if (params.node.uiLevel === 0) {
                return {
                  fontWeight: 'bold',
                  backgroundColor: globalTheme.colors.aqua,
                };
              }
            }}
            groupDefaultExpanded={
              (!hideMainCat && categories?.mainCategory) ||
              categories?.subCategory
                ? 1
                : 0
            }
            suppressAggFuncInHeader={true}
            suppressHorizontalScroll={false} // Make the header sticky
            suppressExcelExport={true}
            onGridReady={onGridReady}
            // autoSizeStrategy={{
            //   type: isMobile ? 'fitCellContents' : 'fitGridWidth',
            // }}
            suppressDragLeaveHidesColumns
          />
        </div>
      </div>
    </>
  );
};
