import { Flex, RadioBox, Text } from 'components/ui';
import { BookingConfig } from 'lib/api/generated';
import { getMainCategory } from 'lib/helpers';
import React, { ChangeEvent, FC } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';
import { globalTheme } from 'styles/global-theme';

interface IMainCategory {
  config?: BookingConfig;
  productType?: string;
  onChange?: (value?: string) => void;
  showTitle?: boolean;
  value?: string;
}

export const MainCategory: FC<IMainCategory> = ({
  config,
  productType,
  onChange,
  showTitle = true,
  value,
}) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);

  if (!config) {
    return null;
  }

  const mainCategory = getMainCategory(config, productType);

  if (!mainCategory || !Array.isArray(mainCategory.values)) {
    return null;
  }

  return (
    <Flex
      gap={1}
      alignItems='center'
      direction={isMobile ? 'column' : 'row'}
      maxWidth={'max-content'}
    >
      {showTitle && (
        <Text
          fontWeight='medium'
          fontSize={isMobile ? 'bookingMobile' : 3}
          maxWidth={'max-content'}
        >
          {mainCategory?.name}
        </Text>
      )}
      <Flex gap={1} wrap='wrap' justifyContent='center'>
        {mainCategory.values.map((el, idx) => (
          <RadioBox
            key={idx}
            value={el['key']}
            label={el['name']}
            type='checkbox'
            name={`radio_select-main-cat`}
            height={globalTheme.space[3]}
            defaultChecked={
              mainCategory?.values?.length === 1 ? true : undefined
            }
            disabled={mainCategory?.values && mainCategory?.values?.length <= 1}
            checked={el.key === value}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                onChange?.(event.target.value);
              } else {
                onChange?.(undefined);
              }
            }}
            bookingCell={isMobile}
          />
        ))}
      </Flex>
    </Flex>
  );
};
