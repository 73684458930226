import { MainCategory, SubCategory } from 'components/category-selector';
import { FilterWrapper } from 'components/filter-wrapper';
import { Flex } from 'components/ui';
import { BookingConfig } from 'lib/api/generated';
import { getMainCategory } from 'lib/helpers';
import React, { FC, SetStateAction, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { breakpoints } from 'styles/breakpoints';

interface IStockRequiredSummaryHeader {
  config: BookingConfig;
  categories: {
    mainCategory?: string;
    subCategory?: string | string[];
  };
  setCategories: React.Dispatch<
    SetStateAction<{
      mainCategory?: string;
      subCategory?: string | string[];
    }>
  >;
}

export const StockRequiredSummaryHeader: FC<IStockRequiredSummaryHeader> = ({
  config,
  categories,
  setCategories,
}) => {
  const isMobile = useMedia(`(max-width: ${breakpoints.medium})`);
  const mainCategory = getMainCategory(config);

  useEffect(() => {
    if (
      mainCategory &&
      mainCategory.values &&
      mainCategory.values?.length === 1 &&
      !categories.mainCategory
    ) {
      setCategories?.({ mainCategory: mainCategory.values[0]?.key });
    }
  }, [mainCategory]);

  return (
    <FilterWrapper>
      <Flex
        gap={1}
        wrap='wrap'
        direction='column'
        alignItems={isMobile ? 'center' : ''}
      >
        <MainCategory
          config={config}
          value={categories?.mainCategory}
          onChange={(el) => {
            {
              /** Assign the main category and reset the sub category */
            }
            setCategories({ mainCategory: el, subCategory: undefined });
          }}
        />
        <SubCategory
          config={config}
          type='checkbox'
          value={categories?.subCategory}
          mainCategory={categories?.mainCategory}
          onChange={(el) =>
            setCategories((prev) => ({ ...prev, subCategory: el }))
          }
        />
      </Flex>
    </FilterWrapper>
  );
};
